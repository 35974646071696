import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { isEmpty } from '../../../../../shared/common';
import { environment } from '../../../../../environments/environment';
import { loadStripe } from '@stripe/stripe-js';

@Component({
  selector: 'donation-payment-update-complete',
  templateUrl: './donation-payment-update-complete.component.html',
  styleUrl: './donation-payment-update-complete.component.css'
})
export class DonationPaymentUpdateCompleteComponent implements OnInit {
  // frags;

  paymentIntentstatus: any;
  donationId: number | null = null;
  orgId: number = -1;
  constructor(private route: ActivatedRoute,
    private router: Router) { }


  ngOnInit(): void {
    // this.checkStatus();
    this.donationId = + (this.route.snapshot.paramMap.get('donation_id') || '');
    this.orgId = + (this.route.snapshot.paramMap.get('org_id') || '');
    this.route.queryParams.subscribe(p => {
      // this.frags = p;
      let clientSecret = p['setup_intent_client_secret'];
      let stripeAccount = p['stripe_account'];
      this.checkStatus(clientSecret, stripeAccount);
    }).unsubscribe();
  }

  async checkStatus(clientSecret: any, stripeAccount: any) {
    if (!clientSecret) {
      return;
    }
    let stripe;
    if (!isEmpty(stripeAccount)) {
      stripe = await loadStripe(environment.stripePublishableKey, { stripeAccount: stripeAccount });
    }
    else {
      stripe = await loadStripe(environment.stripePublishableKey);
    }
    if (stripe) {
      const { setupIntent } = await stripe.retrieveSetupIntent(clientSecret);
      this.paymentIntentstatus = setupIntent?.status;
    }
  }

  navigateToDonate() {
    this.router.navigate(['/', 'my-stuff']);
  }
  // navigateToOrg() {
  //   if (this.orgId) {
  //   this.router.navigate(['/', 'orgs', this.orgId]);
  //   }
  //   else {
  //     this.router.navigate(['/', 'my-donations']);
  //   }
  // }
}
