
<!-- <dynamic-org-banner *ngIf="orgId" [orgId]="orgId"></dynamic-org-banner> -->
<!-- <p>paymentIntentstatus = {{paymentIntentstatus}}</p> -->
<!-- https://dev-app.eventplus.us/my-donations/517/payment-update-complete/?stripe_account=acct_1MKbZeIN6Jff6rEg&setup_intent=seti_1Q6kOKIN6Jff6rEg7vVOyhkD&setup_intent_client_secret=seti_1Q6kOKIN6Jff6rEg7vVOyhkD_secret_QyhnlpPxt8CZl6g3fQMlYgXn8m8jKnq&redirect_status=succeeded
localhost:4200/my-donations/517/payment-update-complete/?stripe_account=acct_1MKbZeIN6Jff6rEg&setup_intent=seti_1Q6kOKIN6Jff6rEg7vVOyhkD&setup_intent_client_secret=seti_1Q6kOKIN6Jff6rEg7vVOyhkD_secret_QyhnlpPxt8CZl6g3fQMlYgXn8m8jKnq&redirect_status=succeeded -->
<div *ngIf="paymentIntentstatus" class="h-screen flex items-center justify-center">
    @switch(paymentIntentstatus) {


    <!-- succeeded -->
    @case('succeeded'){
    <div class="text-center">
        <svg class="mx-auto h-12 w-12 text-primary" fill="currentColor" viewBox="0 0 24 24" stroke="currentColor"
            aria-hidden="true">
            <path
                d="M11.645 20.91l-.007-.003-.022-.012a15.247 15.247 0 01-.383-.218 25.18 25.18 0 01-4.244-3.17C4.688 15.36 2.25 12.174 2.25 8.25 2.25 5.322 4.714 3 7.688 3A5.5 5.5 0 0112 5.052 5.5 5.5 0 0116.313 3c2.973 0 5.437 2.322 5.437 5.25 0 3.925-2.438 7.111-4.739 9.256a25.175 25.175 0 01-4.244 3.17 15.247 15.247 0 01-.383.219l-.022.012-.007.004-.003.001a.752.752 0 01-.704 0l-.003-.001z" />
        </svg>
        <h3 class="mt-2 text-sm font-semibold text-gray-900">Payment update successful! </h3>
        <p class="mt-1 text-sm text-gray-500">Thank you for your continued generosity. You will receive an email.</p>
        <div class="mt-6">
            <button type="button" (click)="navigateToDonate()"
                class="inline-flex items-center rounded-md bg-accent-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-accent-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent-600">
                My Donations
            </button>
        </div>

        <!-- Donation Details-->
        <div class="mt-9">
            <div class="relative">
                <div class="absolute inset-0 flex items-center" aria-hidden="true">
                    <div class="w-full border-t border-gray-300"></div>
                </div>
                <div class="relative flex justify-center">
                    <span class="bg-white px-2 text-sm text-gray-500"></span>
                </div>
            </div>
            <div class="mt-5">
                <!-- <pub-org-donate-view *ngIf="donationId" [donationId]="donationId" [orgId]="orgId"></pub-org-donate-view> -->
            </div>
        </div>
    </div>
    }
    <!-- processing -->
    @case('processing'){
    <div class="text-center">
        <svg class="mx-auto h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
            stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round"
                d="M8.625 12a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
        </svg>

        <h3 class="mt-2 text-sm font-semibold text-gray-900">Processing...</h3>
        <p class="mt-1 text-sm text-gray-500">You will receive an email when your payment processing completes.</p>
        <div class="mt-6">
            <button type="button"
                class="inline-flex items-center rounded-md bg-accent-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-accent-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent-600">
                <svg class="-ml-0.5 mr-1.5 h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path
                        d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z" />
                </svg>
                Donate
            </button>
        </div>
    </div>
    }
    <!-- requires_payment_method -->
    @case('requires_payment_method'){
    <div class="text-center">
        <svg class="mx-auto h-12 w-12 text-warn" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
            stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round"
                d="M18.364 18.364A9 9 0 0 0 5.636 5.636m12.728 12.728A9 9 0 0 1 5.636 5.636m12.728 12.728L5.636 5.636" />
        </svg>
        <h3 class="mt-2 text-sm font-semibold text-gray-900">Your Payment was not successful!</h3>
        <p class="mt-1 text-sm text-gray-500">Please try again with different payment method</p>
        <div class="mt-6">
            <button type="button"
                class="inline-flex items-center rounded-md bg-accent-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-accent-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent-600">
                <svg class="-ml-0.5 mr-1.5 h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path
                        d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z" />
                </svg>
                Donate
            </button>
        </div>
    </div>
    }
    <!-- Something went wrong-->
    @default{
    <div class="text-center">
        <!-- <svg class="mx-auto h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor"
            aria-hidden="true">
            <path vector-effect="non-scaling-stroke" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z" />
        </svg> -->
        <svg class="mx-auto h-12 w-12 text-warn" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
            stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round"
                d="M18.364 18.364A9 9 0 0 0 5.636 5.636m12.728 12.728A9 9 0 0 1 5.636 5.636m12.728 12.728L5.636 5.636" />
        </svg>

        <h3 class="mt-2 text-sm font-semibold text-gray-900">Something went wrong.</h3>
        <p class="mt-1 text-sm text-gray-500">Please try again</p>
        <div class="mt-6">
            <button type="button"
                class="inline-flex items-center rounded-md bg-accent-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-accent-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent-600">
                <svg class="-ml-0.5 mr-1.5 h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path
                        d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z" />
                </svg>
                Donate
            </button>
        </div>
    </div>
    }
    }
</div>